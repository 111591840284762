<template>
  <CHeader with-subheader style="background-color: #4786ff !important;">
    <CHeaderBrand to="/application"></CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-12">
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <CButton color="dark" size="sm" style="margin: 15px;" v-if="$route.path == '/welcome'" @click="logout">SALIR</CButton>
      <CButton color="dark" size="sm" style="margin: 15px;" v-if="$route.path != '/welcome'" @click="$router.push({path:'/welcome'})">VOLVER</CButton>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import router from '../router/index'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import account_ws from '../services/account';
import login from '../services/login';
import ws from '../services/general';

export default {
  name: 'TheSuppliersHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data () {
    return { 
      name: '',
      user_name: '',
      menu: [],
      pathname: 'SALIR'
    }
  },
  mounted: async function() {
    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.name = response.data.name;
      this.user_name = response.data.user.first_name+" "+response.data.user.last_name;
    }

    let response2 = await ws.getMenu("header"); 

    if(response2.type == "success"){
      this.menu = response2.data;
    }

    console.log(this.$route.path);
  },
  methods: {
    logout: function(){
      localStorage.removeItem("account");
      localStorage.removeItem("token");
      localStorage.removeItem("domain");

      router.push("login");
    },
  }
}
</script>
